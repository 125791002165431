import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { EwalletListService } from '../../../data/service/ewalletList.service';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { LoginModel } from '../../../data/models/login.model';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { JwtService } from '../../../data/service/jwt.service';
import { environment } from '../../../../environments/environment'
import { JqueryUiUtils } from '../../../../assets/common/util/jquiutil';
import { ComponentUtil } from '../../../../app/data/util/componentUtil';
import { CouponListCommonUtil } from '../../../data/util/couponListCommonUtil';
import { ErrHandler } from '../../../data/util/errHandler';

@Component({
    selector: 'app-myewallet-search',
    templateUrl: './myewallet-search.component.html',
    styleUrls: ['./myewallet-search.component.scss']
  })
  export class MyEwalleSearchComponent implements OnInit {
       /**api use data */
       couponList:CouponInstanceModel[]=[];//page use result list
       allCouponList:CouponInstanceModel[]=[];//api return result list
       resultBrandMap:Map<string,number>=new Map<string,number>();//api return get the exist brand
       selectCoupon:CouponInstanceModel=null; //popup coupon detail value

       issueBrandList:string[];
       appIssueBrand:string;
       /** filter variable */
       filterBrand:string;
       filterStatus:string;
       // filterType:string;
       filterCouponName:string;

       /** component event variable */
       @Output()
       popDetail=new EventEmitter<any>();

       /** page flag  */
       showCouponList:boolean=false;//not data show flag
       channel:string;
       openDetailPage:string='';

       /** search bar variable */
       searchKeys:string[]=[];//use for page key list
       searchKeySet:Set<string>=new Set<string>();//the set of key
       searchKeyStorgeKey='couponSearchKeyList';
       searchInputId='search_searchCoupon';
       dateFormat:string;
       closeSrc:string;

        /** storage use key*/
       private brandFilterKey:string="myWallet_search_brand_filter";
       private typeFilterKey:string="myWallet_search_type_filter";
       private statusFilterKey:string="myWallet_search_status_filter";
       private nameFilterKey:string="myWallet_search_name_filter";

       constructor(private router: Router,private ewalletListService:EwalletListService
        ,private location: Location,private errMsgUtil:ErrMsgUtil,private jwtUtil:JwtUtil
        ,private popupMsgUtil:PopupMsgUtil,public JwtService:JwtService,
        private translateService:TranslateService,private http: HttpClient,){}

      ngOnInit() {
          this.channel=this.jwtUtil.getSubServiceAppType();
          this.filterCouponName='';
    
          var saveSearchKeyList=localStorage.getItem(this.searchKeyStorgeKey);
          if(saveSearchKeyList){
            var keyList:string[]=JSON.parse(saveSearchKeyList);
            for(var i=0;i<keyList.length;i++){
              this.searchKeySet.add(keyList[i]);
            }
          }
          this.updateSearchKeyList(false);
          console.log(this.searchKeys);
          JqueryUiUtils.loadTextVal(this.searchInputId,this.searchKeys);
          this.init();
          this.closeSrc = this.jwtUtil.getClosePng();

        }

        init(){
          // var sbf=sessionStorage.getItem(this.brandFilterKey);
          // var stf=sessionStorage.getItem(this.typeFilterKey);
          // var ssf=sessionStorage.getItem(this.statusFilterKey);
          // var snf=sessionStorage.getItem(this.nameFilterKey);
          var sbf=null;
          var ssf="All";
          this.filterCouponName=sessionStorage.getItem(this.nameFilterKey);
          this.translateService.get("momentDateFormat").subscribe(
            (res)=>{
                this.dateFormat=res;
            }
        );
    
          // if(!ComponentUtil.checkSelectNotNull(ssf)){
            //  sessionStorage.setItem(this.statusFilterKey,"All");
            //  ssf="All";
          // }
          console.log("session brand:"+sbf+";type:"+null+";status:"+ssf+";name:"+this.filterCouponName);
          this.filterBrand=sbf;
          // this.filterType=stf;
          this.filterStatus=ssf;
          // this.filterCouponName=null;
    
           const _this=this;
           this.initDetail();
        }

        initDetail(){
          this.resultBrandMap.clear();
          this.allCouponList=[];
          this.couponList=[];
          this.popupMsgUtil.loading();
          console.log(this.jwtUtil.getServiceAppToken());
          this.ewalletListService.getSearchCouponList().subscribe(
          //  this.ewalletListService.getCouponList('C').subscribe(
            (res)=>{
              // console.log(res);
              res=this.jwtUtil.setupResponse(res);
              res=res.data;
              res=CouponListCommonUtil.setDayOffAndSort(res,this.resultBrandMap,this.dateFormat);
              if (this.resultBrandMap.size>0){
                var temp = [];
                for (const [key, value] of this.resultBrandMap.entries()){
                  temp.push(key);
                }
                this.appIssueBrand = this.jwtUtil.getIssueBrandFromServiceAppType();
              if(this.appIssueBrand){
                var appIssueBrand = this.appIssueBrand.toLowerCase();
                temp.sort(function(x,y){ return x.toLowerCase().includes(appIssueBrand) ? -1 : y.toLowerCase().includes(appIssueBrand) ? 1 : 0; });
              if (appIssueBrand=='1010'){
                appIssueBrand = '1o1o';
                temp.sort(function(x,y){ return x.toLowerCase().includes(appIssueBrand) ? -1 : y.toLowerCase().includes(appIssueBrand) ? 1 : 0; });
              }
            }
                this.issueBrandList=temp;
              }

              this.couponList=res;
              this.allCouponList=res;
              this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,this.filterBrand,this.filterStatus,null,this.filterCouponName);
              console.log(this.couponList);
              this.showCouponList=true;
              if(this.filterStatus=='PENDING'||
                this.filterStatus=='REDEEMED'||
                this.filterStatus=='EXPIRED' 
              ){
                  var csDiv=document.getElementById('searchStatusDiv');
                  csDiv.scroll(1000,0);
              }
              this.popupMsgUtil.endLoading();
            }
            ,(err)=>{
                 this.showCouponList=true;
                 this.popupMsgUtil.endLoading();
                 const _this=this;
                 function getHandler():ErrHandler{
                    let handler=<ErrHandler><unknown>function () { };
                    handler.handleErr=function(){
      
                    }
                    handler.handleRefreshToken=function(){
                      _this.initDetail();              
                    }
                    return handler;
                 }
                 var eh:ErrHandler=getHandler();
                 this.errMsgUtil.handleErr(eh,err.error);            
               }
            );
        }

        goDetail(id:string){
          var a={'couponId':id,'page':'search'}
          this.popDetail.emit(a);
          // console.log("view detail id is "+detail.couponId);
          // console.log(detail);
          // this.openDetailPage=detail.page;
          this.openDetailPage='search'
          this.popupMsgUtil.loading();
              const _this=this;
              this.ewalletListService.getCouponDetail(id).subscribe(
                (res)=>{
                  //  console.log(res);
                  res=this.jwtUtil.setupResponse(res);
                  this.selectCoupon=res;
                  this.selectCoupon=CouponListCommonUtil.changeCouponBanlanceShow(this.selectCoupon,true);
                  this.popupMsgUtil.endLoading();
                  var btn=document.getElementById('openCouponDetailBtn');
                  btn.click();
                  var mv=document.getElementById('myCouponDetailModal');
                  mv.setAttribute('style','display: block; padding-right:0px;padding-left: 0px;position:flex;top:0%;height:100%;overflow: hidden;overflow-y:scroll;');
                  var initCouponCode=document.getElementById(this.openDetailPage+'couponDetailInitCouponCode');
                  initCouponCode.innerHTML=this.selectCoupon.couponCode;
                  var init=document.getElementById(this.openDetailPage+'couponDetailInit'); 
                  init.click();
                },
                (err)=>{
                  this.popupMsgUtil.endLoading();
                  // mv.setAttribute('style','display: block; padding-right:0px;padding-left: 0px;position:flex;top:30%;height:70%;overflow: hidden;overflow-y:scroll;');
                    function getHandler():ErrHandler{
                        let handler=<ErrHandler><unknown>function () { };
                        handler.handleErr=function(){
                          
                        }
                        handler.handleRefreshToken=function(){
                          _this.goDetail(id);
                        }
                        return handler;
                    }
                    var eh:ErrHandler=getHandler();
                    this.errMsgUtil.handleErr(eh,err.error);
            }
          );
        }

        /* component method  start */
        //1 search bar
        clickSearch(){
          console.log('angular:'+this.filterCouponName);
          console.log('js:'+JqueryUiUtils.getInputValue(this.searchInputId));
          var searchCoupon=document.getElementById(this.searchInputId);
          var jsGetVal=JqueryUiUtils.getInputValue(this.searchInputId);
          jsGetVal=jsGetVal?jsGetVal.trim():jsGetVal;
          this.filterCouponName=jsGetVal;
          searchCoupon.setAttribute('ng-reflect-model',this.filterCouponName);
          // var btn=document.getElementById(this.tab+'_search');
          // btn.click();
          if(this.filterCouponName&&this.filterCouponName!=''){
            this.searchKeySet.delete(this.filterCouponName);
            this.searchKeySet.add(this.filterCouponName);
            this.updateSearchKeyList(true);
            console.log(this.searchKeys);
          }
          this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,this.filterBrand,this.filterStatus,null,this.filterCouponName);
        }

        private updateSearchKeyList(save:boolean){
          this.searchKeys=ComponentUtil.convertSetToArray(this.searchKeySet);
          this.searchKeys=ComponentUtil.getQueueByArray(this.searchKeys,environment.searchKeySaveSize);
          if(save){
            // var saveKeys=ComponentUtil.getQueueByArray(this.searchKeys,environment.searchKeySaveSize);
            var saveStr=JSON.stringify(this.searchKeys);
            localStorage.setItem(this.searchKeyStorgeKey,saveStr);
          }
          // this.searchKeys=ComponentUtil.getQueueByArray(this.searchKeys,environment.searchKeySaveSize);
          this.searchKeys=this.searchKeys.reverse();
          if(save){
            JqueryUiUtils.loadTextVal(this.searchInputId,this.searchKeys);
          }
        }

      //2 brand filter
      brandChange(event){
        this.filterBrand=event;
        this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,this.filterBrand,this.filterStatus,null,this.filterCouponName);
        this.jwtUtil.setupSessionTime();
        // sessionStorage.setItem(this.brandFilterKey,this.filterBrand);
      }
  
      //3 status filter
      statusChange(event){
         this.filterStatus=event;
         this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,this.filterBrand,this.filterStatus,null,this.filterCouponName);
          this.jwtUtil.setupSessionTime();
        //  sessionStorage.setItem(this.statusFilterKey,this.filterStatus);
      }

      //4 close detail
      detailClose(){
          this.location.back();
      }
  }