<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-3" style="padding-top:10px;padding-right:45px;padding-left:15px;margin-top: 10px;">
      <!-- nameCard start-->
      <!--<app-microsite-name-card [nameCardInfo]="nameCardInfo"></app-microsite-name-card>-->
      <app-myewallet-nameCard (qrCode)="openNameCardQrCode()" (share)="openShare()" [tab]="'microsite'" [customerInfo]="nameCardInfo"></app-myewallet-nameCard>
      <!-- nameCard end-->
    </div>
    
    <div class="col-12 col-sm-12 col-md-6 col-lg-9 ">
      <!-- coupon list start-->
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4" *ngFor="let item of mgmCouponListModel" style="padding-top:10px;padding-bottom:10px;padding-left:15px;padding-right:10px;">
          <!--
          <a (click)="gotoCouponDetail(nameCardInfo,item)">
            <div class="card">
              <img class="card-img-top bg-dark " src="{{item&&item.couponItem?lang=='zh-HK'?item.couponItem.imageUrlCht:item.couponItem.imageUrl:''}}">

              <div class="card">
                <img class="card-img card-logo  " src="../../../assets/image/brandlogo/brand_{{item&&item.couponItem?item.couponItem.issueBrand:'CSL'}}.png">
              </div>
              <br><br>
              <div class="card-body">
                <h5 class="card-title">{{item&&item.couponItem?lang=='zh-HK'?item.couponItem.nameCht:item.couponItem.name:''}}</h5>
                <h6 class="card-subtitle mb-2 text-muted ">{{item&&item.couponItem?lang=='zh-HK'?item.couponItem.descriptionCht:item.couponItem.description:''}}</h6>
                <p class="card-text bg-light">{{item.validFrom | date: ('common.dateFormat'|translate) }} {{'common.dateMiddleText' | translate}} {{item.validTo | date: ('common.dateFormat'|translate)}}</p>
              </div>
            </div>
          </a>
          <br>
        -->
            <div class="walletCard" style="padding-left:0px;padding-right:0px;vertical-align: middle;border-radius:15px;margin-right:15px;margin-left: 10px;" >
               <app-myewallet-couponCard [cp]='item' (openDetail)="openDetail(item)" (share)="clickShare()" [tab]="'microsite'"></app-myewallet-couponCard>
            </div>
          </div>

      </div>
      <!-- coupon list start-->

    </div>


  </div>


  <button id="myNameCardQrModalBtn" style="display:none;" data-toggle="modal" data-target="#myNameCardQrModal"></button>
  <div class="modal fade" id="myNameCardQrModal" style="position: absolute;top:30%;">
      <div class="modal-dialog">
            <div class="modal-content" >
                  <div class="modal-body" id="couponDetailModalBody">
                        <!--<i id="nameCardQrClose" class="close popClose" data-dismiss="modal" >×</i>-->
                        <img id="nameCardQrClose" class="close popClose" data-dismiss="modal" src="/assets/common/image/icons/icon_close.png" width="30" height="30"/>
                        <div class="row">
                          <qrcode class = "m-auto couponQr" 
                          [qrdata]="nameCardQrCode?nameCardQrCode:'nameCard'" [size]=200 [level]="'M'" [width]=200></qrcode>  
                        </div>
                  </div>
            </div>
      </div>
  </div>

  <button id="openCouponDetailBtn" style="display:none;" data-toggle="modal" data-target="#myCouponDetailModal"></button>
<div class="modal fade walletCard" id="myCouponDetailModal" style="padding-right:0px;padding-left:0px;">
    <div class="modal-dialog modal-lg walletCard" style="max-width:100%;margin:0px;">
          <div class="modal-content walletCard" >
                <div class="modal-body walletCard" id="couponDetailModalBody" style="padding:0px;margin-right: 15px;">
                      <!--<i id="couponDetailClose" class="close popClose" data-dismiss="modal" >×</i>-->
                      <img id="couponDetailClose" class="close popClose" data-dismiss="modal" src="{{closeSrc}}" width="30" height="30"/>
                      <app-microsite-coupon-detail [nameCardInfo]='nameCardInfo' [item]="selectCoupon" [mode]="'popup'"></app-microsite-coupon-detail>
                </div>
          </div>
    </div>
</div>