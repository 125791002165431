<div class="container-fluid" style="padding-left:0px;padding-right:0px;" (click)="onOpenDetail(cp.couponItem.isPromotional)">
     <div style="position:relative;">
          <img src="{{
          cp.couponItem.imageUrl=='tes112501'||cp.couponItem.imageUrl=='test'
          ?'/assets/common/image/b.jpg'
          :lang == 'zh-HK'?cp.couponItem.imageUrlCht
          :cp.couponItem.imageUrl
          }}" width="100%" height="auto" style="border-top-left-radius:15px;border-top-right-radius:15px;min-height:150px" />

          <img *ngIf="cp.couponItem.issueBrand!='Netvigator'" src="{{sessionStorage('couponCard-'+cp.couponItem.issueBrand)}}"
               style="position:absolute;
               left:-webkit-calc(50%);left:-moz-calc(50%); left:50%;transform:translateX(-50%);bottom:-10px;z-index:12;max-width: 50px;max-height: 40px;" />
          <img *ngIf="cp.couponItem.issueBrand=='Netvigator'" src="{{sessionStorage(cp.couponItem.issueBrand)}}" 
               style="position:absolute;
               left:-webkit-calc(50%);left:-moz-calc(50%); left:50%;transform:translateX(-50%);bottom:-10px;z-index:12;max-width: 50px;max-height: 40px;" />
          <div class="couponCricle" style="position:absolute;width:80px;height:40px;
               left:-webkit-calc(50%-40px);left:-moz-calc(50% - 40px); left:calc(50% - 40px);bottom:0;  
               border-radius:40px 40px 0px 0px ;">
          </div>
     </div>

     <div class="row" style="min-height:50px" *ngIf="cp&&cp.couponItem">
          <div class="col-11">
               <!--<p class="heading5" style="text-align:left;padding-top:12px;padding-left:22px;margin-right:22px;">
               {{lang == 'zh-HK'?cp.couponItem.nameCht:cp.couponItem.name}}</p>-->
               <p class="heading5" data-couponItem style="text-align:left;padding-top:12px;padding-left:22px;margin-right:22px;">
                    {{lang == 'zh-HK'?cp.couponItem.nameCht:cp.couponItem.name}}
               </p>
               <p class="bookRemark1" style="text-align:left;padding-left:22px;margin-right:22px;word-break:break-word;display: -webkit-box;
                 -webkit-box-orient: vertical;
                 -webkit-line-clamp: 3;
                 overflow: hidden;">
                    {{lang == 'zh-HK'?cp.couponItem.descriptionCht:cp.couponItem.description}}
               </p>
               

          </div>
          <!-- <div #bkMarkImg class="col-1"  style="{{cp.source=='SB'?'display:none':''}}">
               <img *ngIf="cp&&!cp.isFavorites" src="/assets/common/image/icons/{{channel}}/icon_bookmark_unable.png"
                    width="24" height="20 "
                    style="border-color:#B5B2B2; font-size: var(--iconSize);position: absolute;right:35px;top:15px;{{(env.threePartRelease==1||cp.couponItem.isPromotional)?'display:none;':''}}"
                    (click)="onBookMark('Y')" />
               <img *ngIf="cp&&cp.isFavorites" src="/assets/common/image/icons/{{channel}}/icon_bookmark_enable.png"
                    width="24" height="20"
                    style=" border-color:#FF5555;  font-size: var(--iconSize);position: absolute;right:35px;top:15px;{{(env.threePartRelease==1||cp.couponItem.isPromotional)?'display:none;':''}}"
                    (click)="onBookMark('N')" />
          </div> -->
     </div>
     <div class="row">
          <div class="col-12">


               <p class="bookRemark1_bold" data-special style="text-align:left;padding-left:22px;margin-right:22px;" *ngIf="tab=='mgm'">
                    {{'successfulReferrals'|translate}}/ {{'totalRequiredReferrals'|translate}}:&nbsp;
                    {{cp&&cp.couponItem?(cp.referralCount?cp.referralCount:'0')+'/'+(cp.couponItem.referralTarget?cp.couponItem.referralTarget:(cp.couponItem.referralTarget==null?'unlimited':0)):'0/0'}}
               </p>

               <p class="bookRemark1_bold" data-detailBalance style="text-align:left;padding-left:22px;margin-right:22px;">
                    <!-- {{(cp && cp.couponItem?(((cp.couponItem.amountDisplay || cp.couponItem.quotaDisplay) && cp.banlanceShowLabel)?cp.banlanceShowLabel+':':''):'')|translate}}{{(cp?(((cp.couponItem.amountDisplay || cp.couponItem.quotaDisplay) && cp.banlanceShowLabel)?cp.banlanceShowValue:''):'')}} -->
                    {{(cp&&((cp.couponItem.amountDisplay && cp.couponItem.couponType=='Cash_Discount') || (cp.couponItem.quotaDisplay && cp.couponItem.couponType!='Cash_Discount'))?cp.banlanceShowLabel:'')|translate}}
                    {{cp&&cp.banlanceShowLabel&&((cp.couponItem.amountDisplay && cp.couponItem.couponType=='Cash_Discount') || (cp.couponItem.quotaDisplay && cp.couponItem.couponType!='Cash_Discount'))?':':''}}
                    {{cp&&((cp.couponItem.amountDisplay && cp.couponItem.couponType=='Cash_Discount') || (cp.couponItem.quotaDisplay && cp.couponItem.couponType!='Cash_Discount'))?cp.banlanceShowValue:''}}
               </p>
               <p class="bookRemark1" style="text-align:left;padding-left:22px;margin-right:22px;"
                    *ngIf="cp&&cp.couponItem&&cp.couponItem.redemptionChannelDesc">
                    {{'redemptionChannel'|translate}}:&nbsp;{{cp&&cp.couponItem?(lang=='zh-HK'?cp.couponItem.redemptionChannelDescCht:cp.couponItem.redemptionChannelDesc):''}}
               </p>
          </div>
     </div>
     <div class="row">
          <div class="col-8" style="padding-bottom:16px;padding-left:26px;padding-top: 2px;" *ngIf="cp.source!='SB'">
               <pre class="couponCardDateRange bookRemark1_couponCode"
               data-validDate data-special style="text-align: center;">{{'validFrom'|translate}}<app-date [date]="cp.validFrom||(cp.couponItem&&cp.couponItem.defaultFromDate)"></app-date>{{'dateMiddleText'|translate}}<app-date [date]="cp.validTo||(cp.couponItem&&cp.couponItem.defaultToDate)"></app-date></pre>
          </div>
          <div class="col-8" style="padding-bottom:16px;padding-left:26px;padding-top: 2px; {{(cp.source=='SB'&&cp.validTo)?'':'visibility: hidden'}}" *ngIf="cp.source=='SB'">
               <pre class="couponCardDateRange bookRemark1_couponCode" 
               data-validDate data-special style="text-align: center;">{{'validTo'|translate}} <app-date [date]="cp.validTo||cp.couponItem.defaultToDate"></app-date></pre>   
          </div>
          <div class="col-4" style="padding-bottom:16px;display: inline; display: flex;  bottom: 0; right: 0;" *ngIf="tab=='mgm'&&cp&&cp.couponItem.isReferrerCoupon&&!cp.couponItem.isPromotional">
               <img src="/assets/common/image/icons/{{channel}}/{{'shareImg'|translate}}.png" width="50" height="50"
                    style="padding-right:8px;" (click)="onShare($event)" />
                    <pre class="heading5" data-special (click)="onShare($event)" style="text-align: center; padding-top: 10px;">{{'share'|translate}}</pre>
          </div>
          
           <div #collectBtn class="col-4" style="{{viewDetailButtonStyle}}" *ngIf="cp?.couponItem?.isPromotional && (cp.couponItem.PCRedemptionMethod=='SMC'||cp.couponItem.PCRedemptionMethod=='DC')">
               <button  class="buttonRedeem"  style="min-width: 60px;max-width: 300px;" (click)="collectDebounce()">{{'collect'|translate}}</button>
          </div> 
          <div class="col-4" style="{{viewDetailButtonStyle}}" *ngIf="(!cp) || (!cp.couponItem) || (!cp.couponItem.isPromotional)  || (cp?.couponItem?.isPromotional && (cp.couponItem.PCRedemptionMethod !='SMC' && cp.couponItem.PCRedemptionMethod!='DC'))">
               <button  class="buttonRedeem" style="min-width: 60px;max-width: 300px;">{{'clickHere'|translate}}</button>
          </div>
     </div>
</div>


<div id="pcScan" style="width:100%;height:100%;padding:0px;">
     <div class="row">
         <div class="col-12">
             <app-myewallet-scanQrCode [couponName]="!cp?'':lang=='zh-HK'?cp.couponItem.nameCht:cp.couponItem.name" (onReturn)="clickScanReturn()" (onScanSuccess)="onScaned($event)"></app-myewallet-scanQrCode>
         </div>
     </div>
 </div>