import { Component, OnInit,OnDestroy, ErrorHandler,Input,Output,EventEmitter} from '@angular/core';
import { DateRangePickerUtils } from '../../../../assets/common/util/daterangepickerutil';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-myewallet-dateRangePicker',
    // directives: [ InfiniteScroll ], 
    templateUrl: './myewallet-dateRangePicker.component.html',
    styleUrls: ['./myewallet-dateRangePicker.component.scss']
})
export class MyEwalletDateRangePickerComponent implements OnInit{
    clearDateStr:string="Clear Date";
    lang = sessionStorage.getItem('lang');

    @Input()
    initDateRange:string;

    @Input()
    id:string;

    @Input()
    page:string;

    @Output()
    onClose= new EventEmitter<any>();

    @Output()
    onEnter= new EventEmitter<any>();

    constructor(private translateService:TranslateService){}

    ngOnInit() {
        var monthFormat='MMM YYYY';
        var dayOfWeekStr=["SUN","MON","TUE","WED","THU","FRI","SAT"];
        var dateFormat='YYYY/MM/DD';
        var separator=' to ';
        this.translateService.get("momentDateFormat").subscribe(
            (res)=>{
                dateFormat=res;
            }
        );
        this.translateService.get("dayOfWeekStr").subscribe(
            (res)=>{
                dayOfWeekStr=res;
            }
        );
        this.translateService.get("dateSeparator").subscribe(
            (res)=>{
                separator=res;
            }
        );
        this.translateService.get("monthFormat").subscribe(
            (res)=>{
                monthFormat=res;
                console.log(monthFormat);
                // var dayOfWeekStr=['1','2','3','4','5','6','7'];
                DateRangePickerUtils.couponDateRange(this.page+'CouponDateRangeText',true,'top:0px;left:0px;right:0px;display:block;width:100%;',this.page+"-"+this.id+'DateRangePickerCompenent',dateFormat,separator,dayOfWeekStr,monthFormat,this.lang,this.page);
                var myewalletDateRangeDiv=document.getElementById(this.page+'CouponDateRangeDiv');
                var myewalletDateRangePickerCompenent=document.getElementById(this.page+"-"+this.id+'DateRangePickerCompenent');
                myewalletDateRangeDiv.appendChild(myewalletDateRangePickerCompenent);
            }
        );




        this.translateService.get("clearDate").subscribe(
            (res)=>{
                this.clearDateStr=res;
                var clearBtn=document.getElementsByClassName('clearBtn');
                if(clearBtn.length>0){
                    for(var i=0;i<clearBtn.length;i++){
                        clearBtn[i].innerHTML=this.clearDateStr;
                    }
                }

            }
        );
    }

    clickClose(){
        this.onClose.emit();
    }

    clickEnter(){
        var str=document.getElementById(this.page+'CouponDateRangeText');
        // str.getAttribute('ng-reflect-model');
        // console.log(str);
        this.onEnter.emit();
    }

    rangeTextClick(){
        var rangeText=document.getElementById(this.page+'CouponDateRangeText');
        rangeText.click();
    }
}