import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router,ActivatedRoute,RouterEvent,NavigationStart,NavigationEnd,Params } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';
// import {  ConfigService } from './data/service/config.service';
import { JwtUtil } from './data/util/jwtUtils';
import { Base64Utils } from '../assets/common/util/base64util';
import { ComponentUtil } from './data/util/componentUtil';
import { PopupMsgUtil } from './data/util/popupMsgUtil';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'ruby-app';
  env='';


  constructor(public translateService:TranslateService
    ,public activateRoute:ActivatedRoute,public router:Router
    ,public location:Location,public jwtUtil:JwtUtil,public popupMsgUtil:PopupMsgUtil,){
  }

  ngOnInit(){
    if(environment.env.indexOf('microsite')>-1){
       document.getElementsByTagName('title')[0].innerHTML='MicroSite';//microsite will show different title
    }else{
      document.getElementsByTagName('title')[0].innerHTML='Coupon eWallet';
    }

    /*
    get client use env
    */  
    var u=navigator.userAgent;
    // var isHeader=this.configService.get('headerMode');
    // var w=document.body.clientWidth;
    // var appVersion=navigator.appVersion;
    var osInfo= {
      trident: u.indexOf('Trident') > -1, 
      presto: u.indexOf('Presto') > -1, 
      webKit: u.indexOf('AppleWebKit') > -1,  
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,  
      mobile: !!u.match(/AppleWebKit.*Mobile.*/),  
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), 
      android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, 
      iPhone: u.indexOf('iPhone') > -1,  
      iPad: u.indexOf('iPad') > -1, 
      webApp: u.indexOf('Safari') == -1, 
      weixin: u.indexOf('MicroMessenger') > -1,  
      // qq: u.match(/\sQQ/i) == " qq"
     };
     if (osInfo.ios || osInfo.iPhone || osInfo.iPad) {
      this.env='ios';
    } else if (osInfo.android) {
         this.env='android';
    } 
    else {
         this.env='web';
    }

    // if(u.indexOf(''))
    // this.env=''+u+"&"+w;
    // alert(u);
    // console.log(u);
    // console.log(this.env);
    localStorage.setItem('env',this.env);
    this.translateService.addLangs(['zh-HK', 'en', 'zh-HK-MyHKT','en-MyHKT']);
    // this.translateService.setDefaultLang('en');
    // const browserLang = this.translateService.getBrowserLang();
    // this.translateService.use(browserLang.match(/zh-HK|en/) ? browserLang : 'en');//i18n init setting

    this.router.events.subscribe((e1:RouterEvent) => {
        if(e1 instanceof NavigationEnd){
           console.log(e1);
           this.setLangs();

           var code=null;
           if(e1.url.match("type=")&&environment.env.indexOf('microsite')>-1){//microsite type param decode to get appType
            this.activateRoute.queryParams.subscribe(params => {
              code = params['type'];
              code=Base64Utils.decode(code);
              this.jwtUtil.setServiceAppType(code);
            });
           }

           const body=document.getElementsByTagName("body")[0];
           if(sessionStorage.getItem("lang")=='zh-HK'){
              body.setAttribute('class','zh');
           }
           else{
              body.setAttribute('class',this.env);
           }

           var mytheme:string=this.jwtUtil.getCssCsl();//default is csl
           console.log(mytheme);
          var serviceAppType=this.jwtUtil.getSubServiceAppType();
          if(serviceAppType&&serviceAppType==this.jwtUtil.getJwtType1010()){
              mytheme=this.jwtUtil.getCss1010();
          }
          else if(serviceAppType&&serviceAppType==this.jwtUtil.getJwtTypeMyHkt()){
            mytheme=this.jwtUtil.getCssMyHkt();
            let tokenStyle=this.jwtUtil.getAccessTokenParam('style');
            console.log('token style: ' + this.jwtUtil.getAccessTokenParam('style'));
            if (tokenStyle == '1010') {
              mytheme = this.jwtUtil.getCss1010ToMyHkt();
            }
          }
          else if(serviceAppType&&serviceAppType==this.jwtUtil.getJwtTypeClubsim()){
            mytheme=this.jwtUtil.getCssClubsim();
          }
          else{
            mytheme=this.jwtUtil.getCssCsl();
          }

           var findTheme:boolean=false;//style change
           var links=document.getElementsByTagName('link');
           for(var i=0;i<links.length;i++){
             var link=links[i];
             if(link.href=='/assets/theme/'+this.jwtUtil.getCssCsl()+'.css'||
                link.href=='/assets/theme/'+this.jwtUtil.getCss1010()+'.css'||
                link.href=='/assets/theme/'+this.jwtUtil.getCssMyHkt()+'.css'||
                link.href=='/assets/theme/'+this.jwtUtil.getCssClubsim()+'.css'
             ){
                //  link.href='/assets/theme/'+mytheme+'.css';
                 link.setAttribute('href','/assets/theme/'+mytheme+'.css');
                //  console.log(document.head.childNodes);
                 findTheme=true;
             }
           }
           if(!findTheme){
              var newLink=document.createElement('link');
              newLink.rel='stylesheet';
              newLink.href='/assets/theme/'+mytheme+'.css';
              document.head.appendChild(newLink);
           }

        }   
  });
  }

  setLangs(){
    let lang=sessionStorage.getItem("lang");//set lang by url param
    let channelType:string =this.jwtUtil.getSubServiceAppType();//set channel type from token
    if(lang && lang == 'zh-HK'){
      if(channelType == 'MyHKT' ) {
        this.translateService.resetLang('zh-HK-MyHKT');
        this.translateService.use('zh-HK-MyHKT');
        this.translateService.currentLang='zh-HK-MyHKT';
      }else {
        this.translateService.use(lang);
        this.translateService.currentLang=lang;
      }
    }else if(lang && lang == 'en') {
      if(channelType == 'MyHKT' ) {
        this.translateService.resetLang('en-MyHKT');
        this.translateService.use('en-MyHKT');
        this.translateService.currentLang='en-MyHKT';
      }else {
        this.translateService.use(lang);
        this.translateService.currentLang=lang;
      }
    }else{
      if(channelType == 'MyHKT' ) {
        this.translateService.resetLang('en-MyHKT');
        this.translateService.use('en-MyHKT');
        this.translateService.currentLang='en-MyHKT';
      }else{
        const browserLang = this.translateService.getBrowserLang();
        this.translateService.use(browserLang.match(/zh-HK|en/) ? browserLang : 'en');//i18n init setting
      }
    } 
  }

  clickCopyErrMsg(){
    // var ccn=document.getElementById('errorModalContent');
    var ccn=document.getElementById('tokenModalContent');
   // var ccn=this.couponDetail.couponCode;
   //  const copyEl = this.elementRef.nativeElement.querySelector('#copyInput');
    const range=document.createRange();
   // console.log(ccn.cloneNode(true));
   //  range.selectNodeContents
   //  ccn.isSameNode
    range.selectNode(ccn);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.translateService.get("msg.copySuccessMsg").subscribe(
       (res)=>{
         console.log(res);
         this.popupMsgUtil.toast(res,2000);
       }
     );
  }

  clickCloseErrMsg(){
    // var token=this.jwtUtil.getServiceAppToken();
    // if(token&&localStorage&&localStorage.getItem("JWTMode")=="1"){
    //   this.router.navigateByUrl('/myewallet?serviceAppToken='+token);
    //   window.location=window.location;
    // }
  }

  clickPopupErrMsg(dur:number){
    //  setTimeout(function(){
        var close=document.getElementById('errorModalClose');
        close.click();
    //  },dur);
  }
}
