import { Component, OnInit, OnDestroy, ErrorHandler, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { ShareUtils } from '../../../../assets/common/util/shareutil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { EwalletMgmService } from '../../../data/service/ewalletMgm.service';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { ErrHandler } from '../../../data/util/errHandler';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { environment } from 'src/environments/environment';
import { Base64Utils } from 'src/assets/common/util/base64util';
import { FavroitesService } from '../../../data/service/favroites.service';
import { CreateCouponService } from '../../../data/service/create-coupon.service';
import { TranslateService } from '@ngx-translate/core';
import { JqueryUiUtils } from 'src/assets/common/util/jquiutil';
import { ScanHandler } from '../../../data/util/scanHandler';
import { QrCodeScanUtil } from '../../../../assets/common/util/qrcodescanutil';
import { JqueryUiPopupModel } from 'src/app/data/models/jquiPopup.mode';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

declare var $: any;


@Component({
    selector: 'app-myewallet-couponCard',
    // directives: [ InfiniteScroll ],
//update heroku-22
    templateUrl: './myewallet-couponCard-component.html',
    styleUrls: ['./myewallet-couponCard-component.scss']
})
export class MyEwalletCouponCardComponent implements OnInit {

    @Input()
    tab: string;

    @Output()
    share = new EventEmitter<any>();

    @Output()
    openDetail = new EventEmitter<any>();

    @Input()
    cp: CouponInstanceModel = null;

    @Input()
    favoritesString: string;

    @Output()
    favoritesStringChanged = new EventEmitter<any>();

    @Output()
    refreshList = new EventEmitter<any>();

    @Output()
    openPromoCpnDetail = new EventEmitter<any>();

    @ViewChild("bkMarkImg") bkMarkImg: ElementRef;
    @ViewChild("collectBtn") collectBtn: ElementRef;

    channel: string;

    lang: string = sessionStorage.getItem("lang");

    env = environment;

    viewDetailButtonStyle: string;
    
    private subject = new Subject();

    sessionStorage(id) {
        return sessionStorage.getItem(id);
    }

    constructor(private jwtUtil: JwtUtil, private ewalletMgmService: EwalletMgmService
        , private favroitesService: FavroitesService
        , private createCouponService: CreateCouponService
        , private popupMsgUtil: PopupMsgUtil, private errMsgUtil: ErrMsgUtil
        , private translateService: TranslateService) {
            this.subject.pipe(throttleTime(3000)).subscribe(()=> {
                this.collectPC();
            }) 
    }

    ngOnInit() {
        this.channel = this.jwtUtil.getSubServiceAppType();
        var pm: JqueryUiPopupModel = new JqueryUiPopupModel();
        pm.autoOpen = false;
        pm.modal = true;
        pm.draggable = false;
        pm.zIndex = 9990;
        pm.diableCloseBtn = true;
        pm.scanLayout = true;
        JqueryUiUtils.initPopup('pcScan', pm);
        if (this.tab == 'mgm') {
            this.viewDetailButtonStyle = 'margin-top: 3.5rem;padding-bottom:0.5rem;display: inline;  bottom: 0.3rem; right: 1rem;left:62%;';
        }  else {
            this.viewDetailButtonStyle = 'margin-top: 3.5rem;padding-bottom:0.5rem;display: inline;  bottom: 0.3rem; right: 1rem;';
        }
    }

    

    collectPC() {
        if (this.cp && this.cp.couponItem.isPromotional) {
            if (this.cp.couponItem.PCRedemptionMethod == 'SMC') {
                this.scan2collect(); 
                setTimeout(() => {
                    console.log("ok");
                    this.popupMsgUtil.endLoading();
                }, 3000); 
            } else if (this.cp.couponItem.PCRedemptionMethod == 'DC') {
                //this.refreshList.emit();
                this.createPC();
                            
            }
        }     
    }

    collectDebounce(){
        this.subject.next();
    }

    scan2collect() {
        if (ShareUtils.isWebShare()) {
            var scanInit = document.getElementById('scanInitBtn');
            scanInit.click();
            JqueryUiUtils.showPopup('pcScan');
        }
        else {
            console.log("1111")
            const _this = this;
            console.log("2222")
            let handler = <ScanHandler><unknown>function () { };
            console.log("3333")
            handler.handleScan = function (code: string) {
                console.log("4444")
                console.log(code);
                code = Base64Utils.decode(code);
                //ErrMsgUtil.popupToken(code);
                _this.onScaned(code);
            };
            console.log("5555")
            QrCodeScanUtil.nativeScan(handler);
        }
    }

    clickScanReturn() {
        JqueryUiUtils.hidePopup('pcScan');
    }

    onScaned(code) {
        JqueryUiUtils.hidePopup('pcScan');
        if (code) {
            if(this.cp.couponItem.redemptionChannel.includes(code)){
                this.createPC();
            }else{
                this.translateService.get("msg.collectFail").subscribe(
                    (res) => {
                        console.log(res);
                        this.popupMsgUtil.toast(res, 3000);
                    }
                );
            }
        }
    }


    createPC() {
        this.popupMsgUtil.loading();
        this.createCouponService.createCoupon(this.cp.couponItem.couponItemCode).subscribe(
            (res) => {
                console.log(res);
                res = this.jwtUtil.setupResponse(res);
                this.cp = res.data;
                this.cp.couponItem.description=this.replaceElement(this.cp.couponItem.description);
                this.cp.couponItem.descriptionCht=this.replaceElement(this.cp.couponItem.descriptionCht);
                if(this.cp.validFrom ) {
                    this.cp.validFrom = dayjs.utc(this.cp.validFrom).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss');    
                 }
                if(this.cp.validTo ) {
                    if(this.cp.source=="SB")
                        this.cp.validTo = dayjs(this.cp.validTo, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');
                    else
                        this.cp.validTo = dayjs.utc(this.cp.validTo).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
                }
                if (res?.code && res.code == "SUCCESS") {
                    this.translateService.get("msg.collectSuccessMsg").subscribe(
                        (res) => {
                            console.log(res);
                            this.popupMsgUtil.endLoading();
                            this.popupMsgUtil.toast(res, 3000);
                        }
                    );
                    this.openPromoCpnDetail.emit(this.cp);
                    // this.refreshList.emit();
                }
            },
            (err) => {
                this.translateService.get("msg.collectFail").subscribe(
                    (res) => {
                        console.log(res);
                        this.popupMsgUtil.endLoading();
                        this.popupMsgUtil.toast(res, 3000);
                    }
                );
            }
        )
        
    }

    onShare(event) {
        event.stopPropagation();
        var langParam = '';
        var shareUrl = environment.micrositeUrl;
        if (this.lang == 'zh-HK') {
            langParam = '&lang=zh-HK'
        }
        var typeParam = '&type=' + Base64Utils.encode(this.jwtUtil.getServiceAppType());
        if (this.cp && this.cp.couponCode && this.cp.refereeCouponCode) {
            this.popupMsgUtil.loading();
            // this.ewalletMgmService.getShareContent(this.cp.couponCode).subscribe(
            this.ewalletMgmService.getShareContent(this.cp.refereeCouponCode).subscribe(
                (res) => {
                    var shareContentText = encodeURIComponent(res.shareContent);
                    var lang = sessionStorage.getItem('lang');
                    var prePrompt= this.cp.couponItem.referralMessage;
                    if(lang) {
                        lang=='zh-HK'?prePrompt=this.cp.couponItem.referralMessageCht:this.cp.couponItem.referralMessage;
                    }
                                        
                    prePrompt = prePrompt.replace('[url]', shareUrl + "/mgm/coupon?code=" + shareContentText + langParam + typeParam);

                    this.popupMsgUtil.endLoading();
                    console.log(res);
                    if (ShareUtils.isWebShare()) {
                        var shareContent = document.getElementById('shareModalContent');
                        shareContent.innerHTML = prePrompt;
                        // shareContent.innerHTML='aa445 refers you a fabulous offer from csl! Hello! I would like to share you the newest special offer from csl! ('+shareUrl+'/mgm/coupon?code='+shareContentText+langParam+typeParam+')';
                        ComponentUtil.copyToClipBoard(prePrompt);
                        this.share.emit();
                    }
                    else {
                        var lang = sessionStorage.getItem('lang');
                        if (!lang) { lang = 'en'; }
                        ShareUtils.share('', '(URL)'
                        , prePrompt, lang);
                    }
                }
                , (err) => {
                    console.log(err);
                    this.popupMsgUtil.endLoading();
                    const _this = this;
                    function getHandler(): ErrHandler {
                        let handler = <ErrHandler><unknown>function () { };
                        handler.handleErr = function () {

                        }
                        handler.handleRefreshToken = function () {
                            _this.onShare(event);
                        }
                        return handler;
                    }
                    var eh: ErrHandler = getHandler();
                    this.errMsgUtil.handleErr(eh, err.error);
                }
            );
        }else if(this.cp && this.cp.source && this.cp.source == 'SB'){
            this.popupMsgUtil.loading();
            let prePrompt = "Click the Link and check out the coupon: "+this.cp.couponItem.referralMessage;
            if(this.lang == 'zh-HK') {
                prePrompt = "點擊連結查看優惠券: "+this.cp.couponItem.referralMessageCht;
            }
            setTimeout(()=> {
                if (ShareUtils.isWebShare()) {
                    var shareContent = document.getElementById('shareModalContent');
                    shareContent.innerHTML = prePrompt;
                    ComponentUtil.copyToClipBoard(prePrompt);
                    this.share.emit();
                }
                else {
                    var lang = sessionStorage.getItem('lang');
                    if (!lang) { lang = 'en'; }
                    ShareUtils.share('', '(URL)'
                    , prePrompt, lang);
                }

                this.popupMsgUtil.endLoading();
            }, 1000);   
            
        }else {
            this.errMsgUtil.popupMsg('','',"Coupon Code not found!",'','');
        }



    }

    onOpenDetail(isPromotional) {
        // if (!$(event.target).closest(this.bkMarkImg.nativeElement).length&&(this.collectBtn?!$(event.target).closest(this.collectBtn.nativeElement).length:true)&&(isPromotional?!isPromotional:true)) {
        //     this.openDetail.emit();
        // }
        if ((this.collectBtn?!$(event.target).closest(this.collectBtn.nativeElement).length:true)&&(isPromotional?!isPromotional:true)) {
            this.openDetail.emit();
        }
    }

    onBookMark(bookmarkFlag: string) {
        var customerId: string = '0011s00000O3SsFAAV';
        var changeFavoritesString: string = '';
        if (bookmarkFlag == 'Y') {//by coco:current unable, change to enable,add value to favoritesString;
            if (this.favoritesString.length > 0) {
                changeFavoritesString = this.favoritesString + ',';
            }
            changeFavoritesString = changeFavoritesString + this.cp.couponCode;
        } else {
            var fav1 = this.favoritesString;
            var fav2: string[] = fav1.split(",");
            fav2.forEach((item, index) => {
                if (item === this.cp.couponCode) fav2.splice(index, 1);
            });
            changeFavoritesString = fav2.join(',');
        }
        console.log('post favorites: ' + changeFavoritesString);
        this.putFavorites(customerId, changeFavoritesString, bookmarkFlag);
    }


    putFavorites(customerId, favourites, bookmarkFlag) {//by coco:put favorites coupon to backend
        this.popupMsgUtil.loading();
        this.favroitesService.putFavoritesList(customerId, favourites).subscribe(
            (res) => {
                this.favoritesString = favourites;
                console.log('favorites:' + res);
                if (bookmarkFlag == 'Y') {
                    this.cp.isFavorites = true;
                }
                else {
                    this.cp.isFavorites = false;
                }
                this.favoritesStringChanged.emit(this.favoritesString);//call succeed,update the favoritesString back to parent component.
                this.popupMsgUtil.endLoading();
            }
            , (err) => {
                this.popupMsgUtil.endLoading();
                const _this = this;
                function getHandler(): ErrHandler {
                    let handler = <ErrHandler><unknown>function () { };
                    handler.handleErr = function () {

                    }
                    handler.handleRefreshToken = function () {
                        //_this.initDetail(customerId);              8
                    }
                    return handler;
                }
                var eh: ErrHandler = getHandler();
                this.errMsgUtil.handleErr(eh, err.error);
            }
        );
    }

    replaceElement(txt:string):string{
        if (txt==null){
            return txt;
        }
        var ttMap:Map<string,string>=new Map<string,string>();
        ttMap.set('&nbsp;',' ');
        ttMap.set('&lt;','<');
        ttMap.set('&gt;','>');
        ttMap.set('&amp;','&');
        ttMap.set('&quot;','"');
        ttMap.set('&apos;',"'");
        ttMap.set('&cent;','￠');
        ttMap.set('&pound;','£');
        ttMap.set('&yen;','¥');
        ttMap.set('&euro;','€');
        ttMap.set('&sect;','§');
        ttMap.set('&copy;','©');
        ttMap.set('&reg;','®');
        ttMap.set('&trade;','™');
        ttMap.set('&times;','×');
        ttMap.set('&divide;','÷');
        ttMap.forEach(
           (v,k,map)=>{
            //    console.log(''+k+":"+v);
               var reg=new RegExp(k+'+',"ig");
               txt=txt.replace(reg,v);
           }
        );
        return txt.replace(/<.*?>/ig,'');
    }
}