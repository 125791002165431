<button id="myewallet-rewards" (click)="init()" style="display:none;"></button>
<button id="myewallet-rewards_search" (click)="searchCouponForCouponName()" style="display:none;"></button>
<div class="container-fluid rewardsScrollFreshView "
      style="padding-top:{{channel!='MyHKT'?(isWeb?114:0):114}}px; overflow:hidden;">
      <div *ngIf="activeTab=='rewards-tab' || activeTab=='myewallet-rewards'" class="row" infinite-scroll
            [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12"
                  style="padding-top:1rem;padding-right:30px;padding-left:15px;">
                  <app-myewallet-nameCard (qrCode)="clickQrCode()" (share)="clickShare()"
                        [tab]="'rewards'"></app-myewallet-nameCard>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 " style="padding-right: 35px;">
                  <app-myewallet-couponstatusfilter [page]="'rewards'" [filterStatus]="filterStatus"
                        (statusChange)="statusChange($event)"></app-myewallet-couponstatusfilter>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3"
                  style="padding-top:10px;padding-bottom:10px;padding-left:10px;padding-right:10px;"
                  *ngFor="let cp of couponList">
                  <div class="walletCard"
                        style="padding-left:0px;padding-right:0px;vertical-align: middle;border-radius:15px;border:1px solid #B5B2B2;">
                        <app-myewallet-couponCard [cp]='cp' [favoritesString]='favoritesString'
                              (favoritesStringChanged)="fromChildFunc($event)" (openDetail)="goDetail(cp.couponCode)"
                              (share)="clickShare()" [tab]="'rewards'"
                              (refreshList)="init()" (openPromoCpnDetail)="openPromoCpnDetail($event)">
                        </app-myewallet-couponCard>
                  </div>
            </div>
            <!-- <p *ngIf="(showCouponList&&couponList&&(nextOffset>=totalNumber))||((channel=='csl'||channel=='1010')&&couponList==undefined&&!(filterStatus=='All'||filterStatus=='REDEEMABLE'))" class="noMoreCoupon">{{'noMoreCoupon'|translate}}</p>
      <p *ngIf="channel=='1010'&&couponList==undefined&&(filterStatus=='All'||filterStatus=='REDEEMABLE')" class="noMoreCoupon">{{'noMoreCoupon_1010'|translate}}</p>
      <p *ngIf="channel=='csl'&&(couponList==undefined?true:couponlist.length==0)&&(filterStatus=='All'||filterStatus=='REDEEMABLE')" class="noMoreCoupon">{{'noMoreCoupon_csl'|translate}}</p> -->

            <!-- <p *ngIf="showCouponList" class="noMoreCoupon"> -->
            <p *ngIf="showCouponList && !(couponList&&couponList.length>0&&allCouponList&&allCouponList.length>0&&totalNumber>(nextOffset))"
                  class="noMoreCoupon">
                  {{((channel == '1010' && (couponList == undefined?true:couponList.length == 0) && (filterStatus ==
                  'All' || filterStatus == 'REDEEMABLE')) ?
                  'noMoreCoupon_1010' :
                  ((channel == 'csl' && (couponList == undefined?true:couponList.length == 0) && (filterStatus == 'All'
                  || filterStatus == 'REDEEMABLE')) ?
                  'noMoreCoupon_csl' :
                  (channel == 'MyHKT' && (couponList == undefined?true:couponList.length == 0))?
                  'noMoreCoupon_MyHKT':
                  'noMoreCoupon')) | translate}}</p>

            <!--
      <div class="col-12" *ngIf="showCouponList&&couponList.length==0">
            <p style="text-align: center;font-size:19px;margin-top:60px;">{{'noCouponFound'|translate}}</p>
      </div>
      <input type="text" id="testToken" style="display: none;"/> 
-->
      </div>
      <!-- <div class="row"> -->
      <!-- <p class="col-12" style="text-align: center;margin-top:10px;" *ngIf="isWeb&&allCouponList&&allCouponList.length>0&&totalNumber>(nextOffset)" (click)="moreCoupon()">{{'more'|translate}}▼</p> -->
      <!--&&totalNumber>(nextOffset+defaultLimit)-->
      <!-- </div> -->
      <div class="row">
            <p class="col-12" style="text-align: center;margin-top:10px;"
                  *ngIf="couponList&&couponList.length>0&&allCouponList&&allCouponList.length>0&&totalNumber>(nextOffset)"
                  (click)="moreCoupon()">{{'more'|translate}}▼</p>
      </div>
</div>

<button id="rewardsRedemptionHistroyModalBtn" style="display: none;" data-toggle="modal"
      data-target="#rewardsredemptionHistoryModal" (click)="clickRedemptionRecords()"></button>
<div class="modal fade secondly_header" id="rewardsredemptionHistoryModal"
      style="padding-right:0px;position:flex;top:0%;height:100%;overflow: hidden;overflow-y:scroll;">
      <div class="secondly_header modal-dialog modal-lg " style="max-width:100%;margin:0px;">
            <div class="secondly_header modal-content" style="padding-left:0px;padding-right:0px;border:none;">
                  <div class="secondly_header modal-body  container-fluid" id="rewardsDetailModalBody">
                        <!--<i id="nameCardQrClose" class="close popClose" data-dismiss="modal" >×</i>-->
                        <img id="rewardsredemptionHistoryPop" class="close popClose" data-dismiss="modal"
                              src="{{closeSrc}}" width="30" height="30" />
                        <app-myewallet-redemptionRecord [page]="'rewards'"></app-myewallet-redemptionRecord>
                  </div>
            </div>
      </div>
</div>