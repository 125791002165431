import { Component, OnInit, OnDestroy, ErrorHandler, Output, Input, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { EwalletListService } from '../../../data/service/ewalletList.service';
import { JwtService } from '../../../data/service/jwt.service';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { CustomerInfoModel } from '../../../data/models/customerInfo.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { LoginModel } from '../../../data/models/login.model';
// import { InfiniteScroll } from 'angular2-infinite-scroll';
import { ErrHandler } from '../../../data/util/errHandler';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { CouponListCommonUtil } from '../../../data/util/couponListCommonUtil';
import { FavroitesService } from '../../../data/service/favroites.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-myewallet-rewards',
  // directives: [ InfiniteScroll ], 
  templateUrl: './myewallet-rewards.component.html',
  styleUrls: ['./myewallet-rewards.component.scss']
})
export class MyEwalletRewardsComponent implements OnInit {
  /**api use data */
  couponList: CouponInstanceModel[] = [];//page use result list
  allCouponList: CouponInstanceModel[] = [];//api return result list
  resultBrandMap: Map<string, number> = new Map<string, number>();//api return get the exist brand

  /** filter variable */
  // filterBrand:string;
  filterStatus: string;
  // filterType:string;
  // filterCouponName:string;

  /** touch event variable */
  touchStartY: number;
  touchEndY: number;

  /** component event variable */
  @Output()
  qrCode = new EventEmitter<any>();

  @Output()
  share = new EventEmitter<any>();

  @Output()
  popDetail = new EventEmitter<any>();

  @Input() activeTab: string;

  /** page flag  */
  showCouponList: boolean = false;//not data show flag
  channel: string;
  appIssueBrand: string;
  favoritesString: string;

  /** storage use key*/
  private brandFilterKey: string = "myWallet_rewards_brands_filter";
  private typeFilterKey: string = "myWallet_rewards_type_filter";
  private statusFilterKey: string = "myWallet_rewards_status_filter";
  private nameFilterKey: string = "myWallet_rewards_name_filter";

  /**split page pram */
  defaultLimit: number = environment.couponPageLimit;
  nextOffset: number = 0;
  totalNumber: number = 0;
  currentY: number = 0;
  dateFormat: string;
  isWeb: boolean;
  scrollLock: boolean;
  env = environment;
  closeSrc:string;

  currentTab: string = 'myewallet-rewards';

  constructor(private router: Router, private ewalletListService: EwalletListService, private jwtService: JwtService
    , private location: Location, private errMsgUtil: ErrMsgUtil, private jwtUtil: JwtUtil, private popupMsgUtil: PopupMsgUtil
    , private favroitesService: FavroitesService, private componentUtil: ComponentUtil, private translateService: TranslateService,) { }


  ngOnInit() {
    this.channel = this.jwtUtil.getSubServiceAppType();
    this.appIssueBrand = this.jwtUtil.getIssueBrandFromServiceAppType();
    // var sv=document.getElementsByClassName('rewardsScrollFreshView')[0];
    // sv.addEventListener("touchstart",this.onTouchStart,false);
    // sv.addEventListener("touchend",this.onTouchEnd,false);
    this.favoritesString = '';
    // if(this.currentTab == this.activeTab){
    //   this.initDetail();
    // }
    this.closeSrc = this.jwtUtil.getClosePng();
  }

  init() {
    // var sbf=sessionStorage.getItem(this.brandFilterKey);
    // var stf=sessionStorage.getItem(this.typeFilterKey);
    var ssf = sessionStorage.getItem(this.statusFilterKey);
    // var snf=sessionStorage.getItem(this.nameFilterKey);
    this.translateService.get("momentDateFormat").subscribe(
      (res) => {
        this.dateFormat = res;
      }
    );
    if (!ComponentUtil.checkSelectNotNull(ssf)) {
      // sessionStorage.setItem(this.statusFilterKey,"REDEEMABLE");
      // ssf="REDEEMABLE";
      sessionStorage.setItem(this.statusFilterKey, "REDEEMABLE,REDEEMED");
      ssf = "REDEEMABLE,REDEEMED";
    }
    console.log("session brand:" + null + ";type:" + null + ";status:" + ssf + ";name:" + null);
    //  this.filterBrand=sbf;
    // this.filterType=stf;
    this.filterStatus = ssf;
    this.scrollLock = false;
    // this.filterCouponName=null;
    this.isWeb = localStorage.getItem('env') ? localStorage.getItem('env') == 'web' : false;
    const _this = this;
    this.initDetail();
  }

  initDetail() {
    this.resultBrandMap.clear();
    this.allCouponList = [];
    this.couponList = [];
    this.showCouponList = false;
    this.searchCouponListNew(0, this.defaultLimit, true, this.filterStatus, (this.filterStatus == 'All' || this.filterStatus.indexOf('REDEEMABLE') > -1) ? true : false, false);
  }

  goDetail(id: string) {
    var a = { 'couponId': id, 'page': 'rewards' }
    this.popDetail.emit(a);
  }

  /* component method  start */
  //1 search bar
  searchCouponForCouponName() {
    // // var sbf=sessionStorage.getItem("myWallet_brand_filter");
    // // var stf=sessionStorage.getItem("myWallet_type_filter");
    // var ssf=sessionStorage.getItem("myWallet_rewards_status_filter");
    // // var snf=sessionStorage.getItem("myWallet_name_filter");
    // // this.filterBrand=sbf;
    // // this.filterType=stf;
    // this.filterStatus=ssf;
    // // this.filterCouponName=snf;
    // var sc=document.getElementById('searchCoupon');
    // console.log(sc);
    // this.filterCouponName=sc.getAttribute('ng-reflect-model');
    // if(!this.filterCouponName||this.filterCouponName==''){
    //    this.clickCouponStatus('All');
    //    sessionStorage.removeItem("myWallet_rewards_status_filter");
    // } 
    // // this.filterResult(this.filterBrand,this.filterStatus,this.filterType,this.filterCouponName);
    // this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,null,this.filterStatus,null,this.filterCouponName);
  }


  //3 status filter
  statusChange(event) {
    this.showCouponList = false;
    var status: string = event;
    this.filterStatus = status;
    sessionStorage.setItem(this.statusFilterKey, status);
    this.nextOffset = 0;
    this.searchCouponListNew(this.nextOffset, this.defaultLimit, true, status, (status == 'All' || status.indexOf('REDEEMABLE') > -1) ? true : false, false);
    this.jwtUtil.setupSessionTime();
  }

  //5 name card event +
  clickQrCode() {
    this.qrCode.emit();
  }

  clickShare() {
    console.log(1);
    this.share.emit();
  }

  clickRedemptionRecords() {
    document.getElementById('myewalletrewards-redemptionRecords').click();
  }
  //5 name card event -

  //6 favorite 
  getFavorites() { //by coco:get favorites coupon from backend
    this.favroitesService.getFavoritesList('1234').subscribe(
      (res) => {
        res = this.jwtUtil.setupResponse(res);
        console.log('favorites:' + JSON.stringify(res.favourites));
        this.favoritesString = JSON.stringify(res.favourites);
        this.favoritesString = this.favoritesString.replace(/"/g, '');
        this.favoritesString = this.favoritesString.replace('null', '');
        if (this.couponList) {
          this.couponList = CouponListCommonUtil.filterResultWithFavriotes(this.couponList, this.filterStatus, this.favoritesString);
        }
      }
      , (err) => {
        this.popupMsgUtil.endLoading();
        const _this = this;
        function getHandler(): ErrHandler {
          let handler = <ErrHandler><unknown>function () { };
          handler.handleErr = function () {

          }
          handler.handleRefreshToken = function () {
            _this.getFavorites();
          }
          return handler;
        }
        var eh: ErrHandler = getHandler();
        this.errMsgUtil.handleErr(eh, err.error);
      }
    );
  }

  fromChildFunc(data) {
    console.log('on click data:' + data);
    this.favoritesString = data;
    // this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,null,this.filterStatus,null,null);
    // if(this.couponList){
    //   this.couponList=CouponListCommonUtil.filterResultWithFavriotes(this.couponList,this.filterStatus,this.favoritesString);
    // }
  }


  searchCouponListNew(offset: number, limit: number, reset: boolean, status: string, hasPromotional: boolean, hasSpringboard: boolean) {
    if (reset) {
      // this.resultBrandMap.clear();
      this.allCouponList = [];
      this.couponList = [];
    }
    this.popupMsgUtil.loading();
    const category = 'R';
    const getList = (offsetCall: number) => {
      combineLatest(
        this.ewalletListService.getCouponList(category, offsetCall, limit, "ALL", status, hasPromotional, hasSpringboard),
      ).subscribe(
        ([res]) => {
          res = this.jwtUtil.setupResponse(res);
          console.log("original couponList")
          console.log(res);
          this.totalNumber = res.totalNumber;
          console.log(this.totalNumber);
          res = res.data;
          res = CouponListCommonUtil.setDayOffAndSort(res, this.resultBrandMap, this.dateFormat);
          console.log("after setDayOffAndSort couponList")
          console.log(res);

          this.allCouponList = this.allCouponList.concat(res);
          console.log("couponList before filter")
          console.log(this.allCouponList);

          this.nextOffset = offsetCall + limit;
          let filterList = CouponListCommonUtil.filter90DayAnd3rdPartyCoupon(this.allCouponList, status, category);
          console.log('couponList before remove duplicate')
          console.log(filterList);
          filterList = CouponListCommonUtil.removeDuplicateCoupon(filterList);
          console.log('couponList after remove duplicate')
          console.log(filterList);
          // if list is not enough and have not requested yet, call searchCoupon api
          if (filterList.length - this.couponList.length < limit && this.allCouponList.length < this.totalNumber) {
            getList(this.nextOffset);
          } else {
            console.log("couponList after filter")
            console.log(filterList);
            console.log("couponList before sort")
            console.log(filterList);
            this.couponList = CouponListCommonUtil.sortCouponList(filterList);
            console.log("couponList after sort")
            console.log(this.couponList);
            this.showCouponList = true;
            if (this.currentY > 0) {
              window.scroll(0, this.currentY);
              this.currentY = 0;
            }
            this.scrollLock = false;
            this.popupMsgUtil.endLoading();
          }
        }
        , (err) => {
          this.showCouponList = true;
          this.popupMsgUtil.endLoading();
          this.scrollLock = false;
          const _this = this;
          function getHandler(): ErrHandler {
            let handler = <ErrHandler><unknown>function () { };
            handler.handleErr = function () {

            }
            handler.handleRefreshToken = function () {
              _this.initDetail();
            }
            return handler;
          }
          var eh: ErrHandler = getHandler();
          this.errMsgUtil.handleErr(eh, err.error);
        }
      );
    }

    getList(offset);
  }

  moreCoupon() {
    var status = sessionStorage.getItem(this.statusFilterKey);
    if (this.allCouponList.length > 0 && this.totalNumber > this.nextOffset) {
      this.currentY = window.scrollY;
      this.searchCouponListNew(this.nextOffset, this.defaultLimit, false, status, false, false);
    } else {
      this.scrollLock = false;
    }
  }

  openPromoCpnDetail(item: any) {
    var a = { 'couponId': item.couponCode, 'couponItemId': item.couponItem.couponItemCode, 'page': 'coupon', isFromPromotional: true, sourceTab: 'myewallet-rewards' };
    this.popDetail.emit(a);
  }
  /** split page search method */


}
